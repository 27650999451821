import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { ClassModel } from "@/models";
import { Teacher } from "@/types/ModelType";
import { Common } from "../Common";

export class ClassForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) sid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = "";
    teacher: Teacher[] = [];
    clid = '';
    student = '';
}

type response = { [key: string]: unknown };

@Component<AdminClassAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminClassAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private classForm = new ClassForm();
    private cityList: object = {};
    private schoolList: { [key: string]: any} = {};
    private teacherList: object = {};
    private selectTeacherList: Teacher[] = [];
    private clid = "";
    private tid = "";
    private identity = window.localStorage.getItem('admin_identity') as string;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;
    private errorMsgMap: { [key: string]: string } = {
        sid: '',
        name: '',
        active: '',
    };

    public async created() {
        const item = {
            clid: this.$route.query.clid,
            token: this.token,
        }
        const editData = await ClassModel.get(item) as response;
        this.assignDataToForm(editData);
        this.selectTeacherList = this.classForm.teacher;

        if (this.identity == "1") {
            this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
            const cid = this.classForm.cid;
            this.schoolList = await Common.getSchool(cid);
        } else {
            const sid = window.localStorage.getItem('admin_sid') as string;
            this.cityList = await Common.getCity(sid); //取得該分校之縣市

            const school = window.localStorage.getItem('admin_school') as string;;
            this.schoolList[0] = {
                sid: sid,
                name: school,
            };
        }
        const sid = this.classForm.sid;
        this.getTeacher(sid);
    }

    private assignDataToForm(data: response) {
        Common.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            clid: string;
            cid: string;
            sid: string;
            name: string;
            teacher: Teacher[];
            student: string;
            active: string;
        };
        this.classForm.clid = item.clid;
        this.classForm.cid = item.cid;
        this.classForm.sid = item.sid;
        this.classForm.name = item.name;
        this.classForm.teacher = item.teacher;
        this.classForm.student = item.student;
        this.classForm.active = item.active;
    }

    //取得該縣市之分校
    private async getSchool(cid: string) {
        if (this.identity == "1") {
            this.classForm.sid = '';
            this.teacherList = {};
            this.tid = '';
            this.schoolList = await Common.getSchool(cid);
        }
    }

    //取得該分校之老師
    private async getTeacher(sid: string) {
        const item: { [key: string]: string } = {
            sid: sid,
            token: this.token,
        }
        const data = await ClassModel.getTeacher(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.teacherList = data.list as object;
    }

    //增加班級老師
    private addTeacher() {
        const [tid, name, phone] = this.tid.split(",");
        if (tid != "") {
            //需判斷重複新增
            const item = {
                tid: tid,
                name: name,
                phone: phone,
            }
            this.selectTeacherList.push(item);
            this.tid = '';
        } else {
            this.errorMsg = "請先選擇老師";
            this.openLB = true;
        }
    }

    //刪除班級老師
    private delTeacher(key: number) {
        if (!this.doubleClick) {
            this.doubleClick = true;

            this.selectTeacherList.splice(key, 1);
            this.doubleClick = false;
        }
    }

    private validateAndSubmit() {
        validate(this.classForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        sid: '',
                        name: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = { //要傳入的form參數
                        sid: this.classForm.sid,
                        name: this.classForm.name,
                        teacherList: this.selectTeacherList,
                        active: this.classForm.active,
                        clid: this.classForm.clid,
                        token: this.token,
                    }
                    this.errNo = await ClassModel.edit(item);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/class");
        }
    }
}
